import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import ReactJson from "react-json-view";

const JsonField = (props) => {
    const { source, label } = props;
    const record = useRecordContext(props);

    const json = JSON.parse(record[source]);

    return <ReactJson name={null} collapsed={false} enableClipboard={false} displayDataTypes={false} displayObjectSize={false} src={json} label={label} />;
}  

JsonField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

JsonField.defaultProps = {
    addLabel: true,
    sortable: false
};

export default JsonField;