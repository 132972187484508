import * as React from "react";
import { List, Pagination, Datagrid, SimpleList, TextField, BooleanField, useListContext } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { GoogleMap, LoadScript, MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';
import DateTimeField from '../components/DateTimeField';

export const WeatherLocationList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const smallStyle = { display: 'inline-block', width: '120px' };
    const smallStyleHeader = { fontWeight: 'bold', fontSize: '1.25em' };

    const ListRowStyle = (record, index) => ({
        backgroundColor: record.isActive ? 'inherit' : '#ffcccb'
    });

    const ListPagination = (props) => <Pagination rowsPerPageOptions={[10, 20, 30, 50, 100]} {...props} />;

    const LocationMap = () => {
        const [activeLocationKey, setActiveLocationKey] = React.useState(null);
        const { data, loaded } = useListContext();
        if (!loaded) return null;

        const handleActiveLocationKey = locationKey => {
            if (locationKey === activeLocationKey) return;
            setActiveLocationKey(locationKey);
        };
      
        const locations = Object.keys(data).map(key => ({
            title: data[key].name,
            key: data[key].id,
            position: {
                lat: data[key].latitude,
                lng: data[key].longitude
            },
            bomObservationInterval: data[key].bomObservationInterval,
            bomLocationID: data[key].bomLocationID,
            bomLocationWMO: data[key].bomLocationWMO,
            latestObservation: data[key].latestObservation
        }));

        return (
            <LoadScript googleMapsApiKey="AIzaSyCFXm5z-29CfWMOBOPTmmoe_rr8SvFE230">
                <GoogleMap mapContainerStyle={{width:'100%',height:450}} center={{lat:-28,lng: 134}} zoom={4}>
                    <MarkerClusterer options={{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'}}>
                        {clusterer =>
                            locations.map(location => (
                                <Marker key={location.key} position={location.position} title={location.title} clusterer={clusterer} onClick={() => handleActiveLocationKey(location.key)}>
                                    {activeLocationKey === location.key ? (
                                        <InfoWindow position={location.position} onCloseClick={() => handleActiveLocationKey(null)}>
                                            <div>
                                                <div><span style={smallStyleHeader}>{location.title}</span></div>
                                                {location.latestObservation ? (
                                                    <div>
                                                        <div><span style={smallStyle}>Latest Obs Time:</span>{location.latestObservation ? `${new Date(location.latestObservation.timestamp).toLocaleString("en-AU", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })} at ${new Date(location.latestObservation.timestamp).toLocaleString("en-US", { hour: 'numeric', minute: 'numeric' })}` : "None"}</div>
                                                        <div><span style={smallStyle}>Temperature:</span>{location.latestObservation.temperature.value.toFixed(1)} {location.latestObservation.temperature.unit}</div>
                                                        <div><span style={smallStyle}>Rain since 9am:</span>{location.latestObservation.rain.value.toFixed(1)} {location.latestObservation.rain.unit}</div>
                                                        <div><span style={smallStyle}>Wind:</span>{location.latestObservation.windSpeed.value} {location.latestObservation.windSpeed.unit} {location.latestObservation.windDirection}</div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div><span style={smallStyle}>Latitude:</span>{location.position.lat}</div>
                                                        <div><span style={smallStyle}>Longitude:</span>{location.position.lng}</div>
                                                        <div><span style={smallStyle}>BoM Location ID:</span>{location.bomLocationID}</div>
                                                        <div><span style={smallStyle}>BoM Location WMO:</span>{location.bomLocationWMO}</div>
                                                        <div><span style={smallStyle}>BoM Obs Interval:</span>{location.bomObservationInterval} mins</div>
                                                    </div>
                                                )}
                                            </div>
                                        </InfoWindow>
                                    ) : null}
                                </Marker>
                            ))
                        }
                    </MarkerClusterer>
                </GoogleMap>
            </LoadScript>
        );
    };

    return (
        <List {...props} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} pagination={<ListPagination />} perPage={100}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => (
                        <div>
                            <div><span style={smallStyle}>Latitude:</span>{record.latitude}</div>
                            <div><span style={smallStyle}>Longitude:</span>{record.longitude}</div>
                            <div><span style={smallStyle}>BoM Obs Interval:</span>{record.bomObservationInterval} mins</div>
                            <div><span style={smallStyle}>Latest Obs Time:</span>{record.latestObservation ? `${new Date(record.latestObservation.timestamp).toLocaleString("en-AU", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })} at ${new Date(record.latestObservation.timestamp).toLocaleString("en-US", { hour: 'numeric', minute: 'numeric' })}` : "None"}</div>
                        </div>
                    )}
                    linkType={null}
                    rowStyle={ListRowStyle}
                />
            ) : (
                <React.Fragment>
                    <LocationMap />
                    <Datagrid rowClick={null} rowStyle={ListRowStyle}>
                        <TextField source="name" label="Name" />
                        <TextField source="latitude" label="Latitude" />
                        <TextField source="longitude" label="Longitude" />
                        <TextField source="bomLocationID" label="BoM Location ID" />
                        <TextField source="bomLocationWMO" label="BoM Location WMO" />
                        <TextField source="bomObservationInterval" label="BoM Observation Interval (mins)" />
                        <DateTimeField source="latestObservation.timestamp" label="Latest Observation" sortable={true} sortBy="latestObservation.timestamp" sortByOrder="DESC" />
                        <BooleanField source="isActive" label="Active?" />
                    </Datagrid>
                </React.Fragment>
            )}
        </List>
    );
};