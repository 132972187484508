import * as React from "react";
import { List, Filter, Pagination, Datagrid, SimpleList, TextField, FunctionField, TextInput, SelectInput, useListContext } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import DateTimeField from '../components/DateTimeField';
import { LineChart, XAxis, YAxis, Tooltip, CartesianGrid, Line, ReferenceLine, Legend, ResponsiveContainer } from "recharts";

export const SpeedTestList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const smallStyle = { display: 'inline-block', width: '120px' };

    const ListFilter = props => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

        const isps = [
            { id: 'Superloop', name: 'Superloop' },
            { id: 'Wireline', name: 'Wireline' },
            { id: 'Vocus Communications', name: 'Vocus Communications' }
        ];

        return (
            <Filter {...props} style={{marginTop: isSmall ? '0px' : '-16px'}}>
                <SelectInput label="ISP" source="isp" choices={isps} alwaysOn={!isSmall} />
                <TextInput label="Server" source="server" alwaysOn={!isSmall} />
            </Filter>
        );
    };

    const getBackgroundColour = (isp, date, download) => {
        const switchOverDate = new Date('2024-05-08T21:00:00');

        const poor = isp === 'Superloop' ? (date >= switchOverDate ? 500 : 100) : 50;
        const ok = isp === 'Superloop' ? (date >= switchOverDate ? 750 : 200) : 75;
        const good = isp === 'Superloop' ? (date >= switchOverDate ? 900 : 250) : 100;
        
        if (download < poor) return '#ffcccb';
        else if (download < ok) return '#ffffed';
        else if (download >= good) return '#f7fff6';
        else return '#fff';
    };

    const ListRowStyle = (record, index) => ({
        backgroundColor: getBackgroundColour(record.isp, new Date(record.timestamp), record.download.value),
        borderBottomWidth: '3px',
        borderBottomStyle: new Date(record.timestamp).getHours() === 0 && new Date(record.timestamp).getMinutes() <= 1 ? 'double' : 'unset'
    });

    const ListPagination = (props) => <Pagination rowsPerPageOptions={[10, 20, 30, 50, 100]} {...props} />;

    const SpeedTestChart = () => {
        const { data, loaded } = useListContext();
        if (!loaded) return null;

        const speedTests = Object.keys(data).map(key => ({
            timestamp: `${new Date(data[key].timestamp).toLocaleString("en-AU", { month: 'short', day: 'numeric' })} ${new Date(data[key].timestamp).toLocaleString("en-AU", { hour12: false, hour: '2-digit', minute: 'numeric' })}`,
            latency: data[key].latency.value,
            download: data[key].download.value,
            upload: data[key].upload.value
        }));
        const maxDownload = Math.max(...speedTests.map(x => x.download));
        const ticks = maxDownload > 500
            ? [0,200,400,600,800,1000,1200]
            : (maxDownload > 150
                ? [0,50,100,150,200,250,300]
                : [0,20,40,60,80,100,120]);

        return (
            <ResponsiveContainer width="100%" height={385}>
                <LineChart
                    data={speedTests}
                    margin={{ top: 25, right: 25, left: 10, bottom: 10 }}
                >
                    <XAxis dataKey="timestamp" reversed />
                    <YAxis ticks={ticks} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                    <ReferenceLine y={maxDownload > 500 ? 1000 : (maxDownload > 150 ? 250 : 100)} stroke="#666" strokeDasharray="3 3" />
                    <ReferenceLine y={maxDownload > 500 ? 50 : (maxDownload > 150 ? 25 : 20)} stroke="#666" strokeDasharray="3 3" />
                    <Line type="monotone" dataKey="latency" name="Latency (ms)" stroke="#ffc658" strokeWidth={2} />
                    <Line type="monotone" dataKey="download" name="Download (Mbps)" stroke="#83a6ed" strokeWidth={2} />
                    <Line type="monotone" dataKey="upload" name="Upload (Mbps)" stroke="#82ca9d" strokeWidth={2} />
                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <List {...props} bulkActionButtons={false} sort={{ field: 'timestamp', order: 'DESC' }} filters={<ListFilter />} pagination={<ListPagination />} perPage={20}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${new Date(record.timestamp).toLocaleString("en-AU", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })} at ${new Date(record.timestamp).toLocaleString("en-US", { hour: 'numeric', minute: 'numeric' })}`}
                    secondaryText={record => (
                        <div>
                            <div>{record.isp ? <span><span style={smallStyle}>ISP / Server:</span>{record.isp} / {record.server}</span> : <span><span style={smallStyle}>Server:</span>{record.server}</span>}</div>
                            <div><span style={smallStyle}>Latency:</span>{record.latency.value} {record.latency.unit}</div>
                            <div><span style={smallStyle}>Download:</span>{record.download.value} {record.download.unit}</div>
                            <div><span style={smallStyle}>Upload:</span>{record.upload.value} {record.upload.unit}</div>
                        </div>
                    )}
                    linkType={null}
                    rowStyle={ListRowStyle}
                />
            ) : (
                <React.Fragment>
                    <SpeedTestChart />
                    <Datagrid rowClick={null} rowStyle={ListRowStyle}>
                        <DateTimeField source="timestamp" label="Timestamp" sortable={true} sortBy="timestamp" sortByOrder="DESC" />
                        <TextField source="isp" label="ISP" emptyText="-" />
                        <TextField source="server" label="Server" />
                        <FunctionField render={record => `${record.latency.value} ${record.latency.unit}`} label="Latency" sortable={true} sortBy="latency" sortByOrder="DESC" />
                        <FunctionField render={record => `${record.download.value} ${record.download.unit}`} label="Download" sortable={true} sortBy="download" sortByOrder="DESC" />
                        <FunctionField render={record => `${record.upload.value} ${record.upload.unit}`} label="Upload" sortable={true} sortBy="upload" sortByOrder="DESC" />
                    </Datagrid>
                </React.Fragment>
            )}
        </List>
    );
};