import * as React from "react";
import { 
    List, Filter, Pagination, Datagrid, SimpleList, TextField, FunctionField, TextInput, SelectInput,
    Show, SimpleShowLayout, useShowController, useRecordContext
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import DateTimeField from '../components/DateTimeField';
import JsonField from '../components/JsonField';

import InformationIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorIcon from '@material-ui/icons/CancelOutlined';

const getLevelWithIcon = (level) => {
    const iconStyle = { height: '20px', margin: '0 2px 0 -4px', verticalAlign: 'middle' };

    let icon = null;
    if (level === 'Error') icon = <ErrorIcon style={iconStyle} />;
    else if (level === 'Warning') icon = <WarningIcon style={iconStyle} />;
    else icon = <InformationIcon style={iconStyle} />;

    return <span>{icon}{level}</span>;
};

export const AppLogList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const smallStyle = { display: 'inline-block', width: '120px' };

    const ListFilter = props => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

        const levels = [
            { id: 'Information', name: getLevelWithIcon('Information') },
            { id: 'Warning', name: getLevelWithIcon('Warning') },
            { id: 'Error', name: getLevelWithIcon('Error') }
        ];

        return (
            <Filter {...props} style={{marginTop: isSmall ? '0px' : '-16px'}}>
                <SelectInput label="Level" source="level" choices={levels} alwaysOn={!isSmall} />
                <TextInput label="Message" source="message" alwaysOn={!isSmall} />
            </Filter>
        );
    };

    const getBackgroundColour = (level) => {        
        if (level === 'Error') return '#ffcccb';
        else if (level === 'Warning') return '#ffffed';
        else return '#fff';
    };

    const ListRowStyle = (record, index) => ({
        backgroundColor: getBackgroundColour(record.level),
        borderBottomWidth: '3px',
        borderBottomStyle: new Date(record.timestamp).getHours() === 0 && new Date(record.timestamp).getMinutes() <= 1 ? 'double' : 'unset'
    });

    const ListPagination = (props) => <Pagination rowsPerPageOptions={[10, 20, 30, 50, 100]} {...props} />;

    return (
        <List {...props} bulkActionButtons={false} sort={{ field: 'timestamp', order: 'DESC' }} filters={<ListFilter />} pagination={<ListPagination />} perPage={30}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${new Date(record.timestamp).toLocaleString("en-AU", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })} at ${new Date(record.timestamp).toLocaleString("en-US", { hour: 'numeric', minute: 'numeric' })}`}
                    secondaryText={record => (
                        <div>
                            <div><span style={smallStyle}>Application:</span>{record.application}</div>
                            <div><span style={smallStyle}>Level:</span>{record.level}</div>
                            <div><span style={smallStyle}>Message:</span>{record.message}</div>
                        </div>
                    )}
                    linkType="show"
                    rowStyle={ListRowStyle}
                />
            ) : (
                <Datagrid rowClick="show" rowStyle={ListRowStyle}>
                    <DateTimeField source="timestamp" label="Timestamp" sortable={true} sortBy="timestamp" sortByOrder="DESC" />
                    <TextField source="application" label="Application" />
                    <FunctionField render={record => getLevelWithIcon(record.level)} label="Level" />
                    <TextField source="message" label="Message" />
                </Datagrid>
            )}
        </List>
    );
};

export const AppLogShow = props => {
    const { loading } = useShowController(props);
    if (loading) return null;

    const AppLogShowLayout = () => {
        const record = useRecordContext();
        if (!record) return null;

        return (
            <SimpleShowLayout>
                <TextField source="id" label="ID" />
                <DateTimeField source="timestamp" label="Timestamp" sortable={true} sortBy="timestamp" sortByOrder="DESC" />
                <TextField source="application" label="Application" />
                <FunctionField render={record => getLevelWithIcon(record.level)} label="Level" />
                <TextField source="message" label="Message" />
                {record.exception && <TextField source="exception" label="Exception" component="pre" />}
                {record.data && <JsonField source="data" label={`Data${record.dataType ? ` (${record.dataType})` : ''}`} />}
                {record.entityId && <TextField source="entityId" label={record.entityName ? `${record.entityName} ID` : 'Entity ID'} />}
            </SimpleShowLayout>
        );
    };

    return (
        <Show {...props}>
            <AppLogShowLayout />
        </Show>
    );
};
