import { fetchUtils } from 'react-admin';
import Config from "./configProvider";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('auth');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
    getList: async (resource, params) => {
        let uri = `${Config.API}/${resource.endsWith('-lookup') ? resource.substring(0, resource.length - 7) : resource}/search?pageNumber=${params.pagination.page}&pageSize=${params.pagination.perPage}&sortBy=${params.sort.field}&sortOrder=${params.sort.order}`;
        if (resource === 'app-log') {
            if (params.filter.level) uri += `&level=${params.filter.level}`;
            if (params.filter.message) uri += `&message=${params.filter.message}`;
        }
        else if (resource === 'speed-test') {
            if (params.filter.isp) uri += `&isp=${params.filter.isp}`;
            if (params.filter.server) uri += `&server=${params.filter.server}`;
        }
        else if (resource === 'weather-location') {
            uri += `&includeLatestObservations=true`;
        }
        else if (resource === 'weather-location-lookup') {
            uri += `&includeLatestObservations=false`;
        }
        else if (resource === 'weather-observation') {
            if (params.filter.weatherLocationId) uri += `&weatherLocationId=${params.filter.weatherLocationId}`;
        }

        return httpClient(uri)
            .then(({ headers, json }) => ({
                data: json === undefined || json === null ? [] : json,
                total: json === undefined || json === null ? 0 : parseInt(headers.get('content-range').split('/').pop(), 10),
            }));
    },

    getMany: (resource, params) => {
        return httpClient(`${Config.API}/${resource.endsWith('-lookup') ? resource.substring(0, resource.length - 7) : resource}/search?pageNumber=1&pageSize=1000&sortBy=&sortOrder=ASC`)
            .then(({ headers, json }) => ({
                data: json,
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            }));
    },

    getOne: async (resource, params) => {
        return httpClient(`${Config.API}/${resource}/${params.id}`)
            .then(({ json }) => ({ 
                data: json 
            }));
    },

    create: async (resource, params) => {
        return httpClient(`${Config.API}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            })   
            .then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }));
    },

    update: (resource, params) => {
        return httpClient(`${Config.API}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            })
            .then(() => ({ 
                data: params.data 
            }));
    },

    delete: (resource, params) => {
        return httpClient(`${Config.API}/${resource}/${params.id}`, {
                method: 'DELETE',
                body: JSON.stringify(params.data),
            })
            .then(() => ({ 
                data: params.data 
            }));
    }
};

export default dataProvider;