import * as React from "react";
import { List, Filter, Pagination, Datagrid, SimpleList, TextField, FunctionField, ReferenceInput, SelectInput } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import DateTimeField from '../components/DateTimeField';

export const WeatherObservationList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const smallStyle = { display: 'inline-block', width: '120px' };

    const ListFilter = props => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

        return (
            <Filter {...props} style={{marginTop: isSmall ? '0px' : '-16px'}}>
                <ReferenceInput label="Location" source="weatherLocationId" reference="weather-location-lookup" perPage={1000} sort={{ field: 'name', order: 'ASC' }} allowEmpty={true} alwaysOn={!isSmall}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </Filter>
        );
    };

    const ListPagination = (props) => <Pagination rowsPerPageOptions={[10, 20, 30, 50, 100]} {...props} />;

    return (
        <List {...props} bulkActionButtons={false} sort={{ field: 'observationDateTime', order: 'DESC' }} filters={<ListFilter />} pagination={<ListPagination />} perPage={20}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${new Date(record.observation.timestamp).toLocaleString("en-AU", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })} at ${new Date(record.observation.timestamp).toLocaleString("en-US", { hour: 'numeric', minute: 'numeric' })}`}
                    secondaryText={record => (
                        <div>
                            <div><span style={smallStyle}>Location:</span>{record.location}</div>
                            <div><span style={smallStyle}>Temperature:</span>{record.observation.temperature.value.toFixed(1)} {record.observation.temperature.unit}</div>
                            <div><span style={smallStyle}>Rain since 9am:</span>{record.observation.rain.value.toFixed(1)} {record.observation.rain.unit}</div>
                            <div><span style={smallStyle}>Wind:</span>{record.observation.windSpeed.value} {record.observation.windSpeed.unit} {record.observation.windDirection}</div>
                        </div>
                    )}
                    linkType={null}
                />
            ) : (
                <Datagrid rowClick={null}>
                    <DateTimeField source="observation.timestamp" label="Observation Time" sortable={true} sortBy="timestamp" sortByOrder="DESC" />
                    <TextField source="location" label="Location" />
                    <FunctionField render={record => `${record.observation.temperature.value.toFixed(1)} ${record.observation.temperature.unit}`} label="Temperature" sortable={true} sortBy="temperature" sortByOrder="DESC" />
                    <FunctionField render={record => `${record.observation.rain.value.toFixed(1)} ${record.observation.rain.unit}`} label="Rain since 9am" sortable={true} sortBy="rain" sortByOrder="DESC" />
                    <FunctionField render={record => `${record.observation.windSpeed.value} ${record.observation.windSpeed.unit} ${record.observation.windDirection}`} label="Wind" sortable={true} sortBy="wind" sortByOrder="DESC" />
                </Datagrid>
            )}
        </List>
    );
};