const configData = {
    Dev: {
        Auth: 'https://rl.couri.dev/api/auth',
        API: 'https://localhost:7205/api'
    },
    Prod: {
        Auth: 'https://rl.couri.dev/api/auth',
        API: 'https://status.couri.dev/api',
    }
};


const devDomains = ['localhost:3000', '10.25.0.10:3000', '10.25.0.14:3000'];
const prodDomains = ['status.couri.dev', 'status.anthonycouri.com'];

const domain = window.location.host;

let config = null;
if (devDomains.includes(domain)) {
    config = configData.Dev;
} else if (prodDomains.includes(domain)) {
    config = configData.Prod;
}

export default config;