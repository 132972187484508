import * as React from "react";
import { Admin, Resource } from 'react-admin';
import Layout from './Layout';
import LoginPage from './LoginPage';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

import { AppLogList, AppLogShow } from './views/appLogs';
import { SpeedTestList } from './views/speedTests';
import { WeatherLocationList } from './views/weatherLocations';
import { WeatherObservationList } from './views/weatherObservations';

import AppLogIcon from '@material-ui/icons/Description';
import SpeedTestIcon from '@material-ui/icons/Speed';
import WeatherLocationIcon from '@material-ui/icons/LocationOn';
import WeatherObservationIcon from '@material-ui/icons/WbSunny';

const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={LoginPage} layout={Layout}>
        <Resource
            name="app-log"
            options={{ category: "Monitoring", label: "App Logs" }}
            icon={AppLogIcon}
            list={AppLogList}
            show={AppLogShow}
        />
        <Resource
            name="speed-test"
            options={{ category: "Monitoring", label: "Speed Tests" }}
            icon={SpeedTestIcon}
            list={SpeedTestList}
        />
        <Resource
            name="weather-location"
            options={{ category: "Weather", label: "Weather Locations" }}
            icon={WeatherLocationIcon}
            list={WeatherLocationList}
        />
        <Resource
            name="weather-observation"
            options={{ category: "Weather", label: "Weather Observations" }}
            icon={WeatherObservationIcon}
            list={WeatherObservationList}
        />
        <Resource name="weather-location-lookup" />
    </Admin>
);

export default App;